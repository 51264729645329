import * as React from "react";
import HomeView from "../views/home";
import { Quiz, MenuBook, IntegrationInstructions, RateReview } from "@mui/icons-material";

const homeList = [
  {
    title: "Aptitude Quiz",
    icon: Quiz,
    redirectUrl: "/education/aptitude_quiz",
    description:
      "An aptitude quiz is a type of assessment tool designed to evaluate an individual's potential to learn or perform specific tasks or activities. It typically consists of a series of questions or tasks that measure a person's cognitive abilities, skills, and interests in different areas.",
  },
  {
    title: "Aptitudes",
    icon: MenuBook,
    redirectUrl: "/education/aptitudes",
    description:
      "Aptitude refers to a person's inherent or acquired ability to learn, understand, and perform specific tasks or activities. It is often associated with natural talent or potential, but it can also be developed through education, training, and practice.",
  },
  // {
  //   title: "Programming",
  //   icon: IntegrationInstructions,
  //   redirectUrl: "/education/programming",
  //   description:
  //     "Programming is a powerful tool that allows individuals to bring their ideas to life and create solutions to complex problems in various domains.",
  // },
  {
    title: "Speech Rating",
    icon: RateReview,
    redirectUrl: "/education/speechRating",
    description:
      "Programming is a powerful tool that allows individuals to bring their ideas to life and create solutions to complex problems in various domains.",
  },
];

const EducationScreen = () => {
  return <HomeView title="" dataProvider={homeList} />;
};

export default EducationScreen;
