import { AxiosCall } from "./apiCall";

const fetchJobs = (appData: any, setAppData: any) => {
    AxiosCall("get", {
    }, "qweb/getJobs", (res: any) => {
        if (res.result)
            setAppData({...appData, jobs: res.result});
    })
}

const fetchEvents = (appData: any, setAppData: any) => {
    AxiosCall("get", {
    }, "qweb/getEvents", (res: any) => {
        if (res.result)
            setAppData({...appData, events: res.result});
    })
}

const fetchProjects = (appData: any, setAppData: any) => {
    AxiosCall("get", {
    }, "qweb/getProjectList", (res: any) => {
        if (res.result)
            setAppData({...appData, projects: res.result});
    })
}

const addProject = (appData: any, setAppData: any, payload: any) => {
    AxiosCall("post", payload, "qweb/addProject", (res: any) => {
        if (res.result)
            setAppData({...appData, projects: [...appData.projects, res.result]});
    })
}

const updateProject = (appData: any, setAppData: any, payload: any) => {
    AxiosCall("post", payload, "qweb/updateProject", (res: any) => {
        if (res.result)
            setAppData({ ...appData, projects: appData.projects.map((event: any) => event.id === res.result.id ? res.result : event) });
    })
}

const addJob = (appData: any, setAppData: any, payload: any) => {
    AxiosCall("post", payload, "qweb/addJob", (res: any) => {
        if (res.result)
            setAppData({...appData, jobs: [...appData.jobs, res.result]});
    })
}

const UpdateJob = (appData: any, setAppData: any, payload: any) => {
    AxiosCall("post", payload, "qweb/updateJob", (res: any) => {
        if (res.result)
            setAppData({ ...appData, jobs: appData.jobs.map((event: any) => event.id === res.result.id ? res.result : event) });
    })
}

const addEvent = (appData: any, setAppData: any, payload: any) => {
    AxiosCall("post", payload, "qweb/addEvent", (res: any) => {
        if (res.result)
            setAppData({...appData, events: [...appData.events, res.result]});
    })
}

const updateEvent =  (appData: any, setAppData: any, payload: any) => {
    AxiosCall("post", payload, "qweb/updateEvent", (res: any) => {
        if (res.result)
            setAppData({ ...appData, events: appData.events.map((event: any) => event.id === res.result.id ? res.result : event) });
    })
}

const saveAnswer =  (payload: any) => {
    AxiosCall("post", payload, "qweb/saveAnswer", (res: any) => {})
}

const update =  (payload: any, setUserDetails: any, setAppData: any, appData: any) => {
    AxiosCall("post", payload, "qweb/update", (res: any) => {
        if (res.result) {
            setAppData({ ...appData, events: appData.events.map((event: any) => event.id === res.member.id ? res.member : event) });
            setUserDetails(res.member);
        }
    })
}

const getMember = (payload: any, setUserDetails: any, appData: any, setAppData: any) => {
    AxiosCall("post", payload, "qweb/getMemberDetails", (res: any) => {
        if (res.result) {
            localStorage.setItem('user_data', JSON.stringify(res.result));
            setUserDetails(res.result);
            fetchEvents(appData, setAppData)
        }
    })
}

const signin = (payload: any, setUserDetails: any, appData: any, setAppData: any, callBack: any) => {
    AxiosCall("post", payload, "qweb/signin", (res: any) => {
        if (res.status) {
            localStorage.setItem('user_data', JSON.stringify(res.result));
            localStorage.setItem('token', res.token);
            setUserDetails(res.result);
            fetchEvents(appData, setAppData);
            callBack && callBack(res.result);
        }
    })
}

const deleteProject = (payload: any, callBack: any) => {
    AxiosCall("post", payload, "qweb/deleteProject", (res: any) => {
        if (res.status) {
            callBack && callBack(res.result);
        }
    })
}

const deleteJob = (payload: any, callBack: any) => {
    AxiosCall("post", payload, "qweb/deleteJob", (res: any) => {
        if (res.status) {
            callBack && callBack(res.result);
        }
    })
}

const deleteEvent = (payload: any, callBack: any, setAppData: any, appData: any) => {
    AxiosCall("post", payload, "qweb/deleteEvent", (res: any) => {
        if (res.status) {
            // setAppData({...appData, events: [...appData.events, res.result]});
            callBack && callBack(res.result);
        }
    })
}

export const DoApicall = (apiName: any, appData: any, setAppData: any, payload: any, setUserDetails?: any, callBack?: any) => {
    if (apiName === "/freelance")
        fetchJobs(appData, setAppData);
    if (apiName === "/quiz")
        fetchEvents(appData, setAppData);
    if (apiName === "/apps")
        fetchProjects(appData, setAppData);
    if (apiName === "addProject")
        addProject(appData, setAppData, payload);
    if (apiName === "updateProject")
        updateProject(appData, setAppData, payload);
    if (apiName === "addJob")
        addJob(appData, setAppData, payload);
    if (apiName === "updateJob")
        UpdateJob(appData, setAppData, payload);
    if (apiName === "addEvent")
        addEvent(appData, setAppData, payload);
    if (apiName === "updateEvent")
        updateEvent(appData, setAppData, payload);
    if (apiName === "update")
        update(payload, setUserDetails, setAppData, appData);
    if (apiName === "getMember")
        getMember(payload, setUserDetails, appData, setAppData);
    if (apiName === "saveAnswer")
        saveAnswer(payload);
    if (apiName === "signin")
        signin(payload, setUserDetails, appData, setAppData,callBack);
    if (apiName === "deleteProject")
        deleteProject(payload, callBack);
    if (apiName === "deleteJob")
        deleteJob(payload, callBack);
    if (apiName === "deleteEvent")
        deleteEvent(payload, callBack,setAppData, appData);
}