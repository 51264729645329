import React, { useState, useRef } from 'react';
import { Button, Typography, Box, TextField, Accordion, AccordionSummary, AccordionDetails, Grid } from '@mui/material';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import { ExpandMore, Mic, Speaker, VolumeUp } from '@mui/icons-material';
import TwoFriend from "../source/conversation/twoFriend.json";
import Customerandclerk from "../source/conversation/customerandclerk.json";
import Attendanceissue from "../source/conversation/attendanceissue.json";
import Reactinterview from "../source/conversation/reactinterview.json";
import Bhagavadgita from "../source/conversation/personalInterests/bhagavadgita.json";
import Book from "../source/conversation/personalInterests/book.json";
import Dreamvacations from "../source/conversation/travel/dreamvacations.json";
import Favoritemovies from "../source/conversation/personalInterests/favoritemovies.json";
import Hobbies from "../source/conversation/personalInterests/hobbies.json";
import Travel from "../source/conversation/travel/travel.json";
import Tvshows from "../source/conversation/personalInterests/tvshows.json";

const SpeechToText = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [transcribedText, setTranscribedText] = useState('');
  const recognitionRef = useRef<any>(null);
  const [messages, setMessages] = useState<any>(TwoFriend);
  const [grade, setGrade] = useState<any>('');
  const [unMatched, setUnMatched] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const dataProvider = [{ title: "High School Reunion(Two Friend)", data: TwoFriend },
  { title: "Buy Shoes(Customer & Clerk)", data: Customerandclerk },
  { title: "Attendance Issue(Student & Teacher)", data: Attendanceissue },
  // { title: "React Interview(Interviewer & Candidate)", data: Reactinterview },
  { title: "Bhagavadgita", data: Bhagavadgita },
  { title: "Book", data: Book },
  { title: "Dream Vacations", data: Dreamvacations },
  { title: "Favorite Movies", data: Favoritemovies },
  { title: "Hobbies", data: Hobbies },
  { title: "Travel", data: Travel },
  { title: "Tv Shows", data: Tvshows }];

  const handleSpeech = (message: any) => {
    if (!('speechSynthesis' in window)) {
      alert('Your browser does not support the Web Speech API');

      return;
    }

    const utterance = new SpeechSynthesisUtterance(message);
    utterance.lang = 'en-US';
    window.speechSynthesis.speak(utterance);
  };

  const evaluateSpeech = (targetSentence: any, spokenText: any) => {
    const targetWords = targetSentence.toLowerCase().replace(/[.,!?]/g, '').split(' ');
    const spokenWords = spokenText.toLowerCase().replace(/[.,!?]/g, '').split(' ');
    const tempUnMatched: any = [];

    // Count the number of matching words
    const matchedWords = spokenWords.filter((word: any) => {
      return targetWords.includes(word);
    }).length;
    targetWords.filter((word: any) => {
      if (!spokenWords.includes(word)) tempUnMatched.push(word)
      return spokenWords.includes(word);
    })
    const accuracy = (matchedWords / targetWords.length) * 100;

    // Assign a grade based on accuracy
    if (accuracy > 90) setGrade("A");
    else if (accuracy > 75) setGrade("B");
    else if (accuracy > 50) setGrade("C");
    else setGrade("D");

    setUnMatched(tempUnMatched);
  };

  const startRecording = (txt: any, index: number) => {
    if (!('webkitSpeechRecognition' in window)) {
      alert('Your browser does not support the Web Speech API');
      return;
    }

    setUnMatched([]);
    const SpeechRecognition: any = window.webkitSpeechRecognition;

    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = false;
    recognitionRef.current.lang = 'en-US';

    recognitionRef.current.onstart = () => {
      setIsRecording(true);
    };

    recognitionRef.current.onresult = (event: any) => {
      const transcript = Array.from(event.results)
        .map((result: any) => result[0])
        .map(result => result.transcript)
        .join('');

      setTranscribedText(transcript);
      evaluateSpeech(txt, transcript);
    };

    recognitionRef.current.onerror = (event: any) => {
      console.error('Speech recognition error', event.error);
    };

    recognitionRef.current.onend = () => {
      setIsRecording(false);
    };

    recognitionRef.current.start();
    setSelectedIndex(index);
  };

  const stopRecording = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
    setIsRecording(false);
  };

  return (
    <Box sx={{ margin: 'auto', padding: 2, background: "white" }}>
      <Typography variant="h6" gutterBottom>
        Conversation
      </Typography>
      <List>
        {dataProvider.map((con: any) => (
          <Accordion key={con.title}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>{con.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List sx={{ overflow: 'auto', marginBottom: 2 }}>
                {con.data.map((message: any, index: any) => (
                  <>
                    <ListItem key={index} alignItems="center">
                      <ListItemAvatar>
                        <Avatar>{String(message.speaker).slice(0, 1)}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        secondary={message.speaker}
                        sx={{
                          borderRadius: 1,
                          p: 1,
                        }}
                      > </ListItemText>
                      <Button onClick={() => handleSpeech(message.message)}>
                        <VolumeUp />
                      </Button>
                      <Button onClick={isRecording ? stopRecording : () => startRecording(message.message, index)}>
                        <Mic />
                      </Button>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={<span style={{ textTransform: 'none' }}>{message.message}</span>}
                        sx={{
                          borderRadius: 1,
                          p: 1,
                        }}
                      > </ListItemText>
                    </ListItem>
                    {index === selectedIndex && <ListItem>
                      <ListItemText color='primary' primary={"Grade: " + grade} secondary={"Missed Words:  " + unMatched.join(",")} sx={{
                        borderRadius: 1,
                        p: 1,
                      }} />
                    </ListItem>}
                  </>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </List>
    </Box>
  );
};

export default SpeechToText;