import React, { useState } from 'react';
import {
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItemText,
    IconButton,
    Grid,
    Tooltip,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    ListItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { useAppContext } from '../provider/appProvider';

const FreelanceTaskScreen = () => {
    const { appData, callAPISer, openToast, userDetails } = useAppContext();
    const [openDialog, setOpenDialog] = useState(false);
    const [newTask, setNewTask] = useState({ category: '', title: '', description: '', youtubeUrl: '', payment: '' });

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOnDelete = (id: any) => {
        callAPISer("deleteJob", { _id: id }, () => {
            openToast({ message: "Job Deleted!!" });
        });
    }

    const handleAddTask = () => {
        callAPISer("addJob", newTask);
        handleCloseDialog();
    };

    return (
        <Container maxWidth="lg">
            <Typography variant="h6" gutterBottom>
                Freelance Task
                {userDetails.isAdmin && <Tooltip title="Add Task" aria-label="add-task">
                    <IconButton color="primary" onClick={handleOpenDialog}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>}
            </Typography>
            <List>
                {Boolean(appData.jobs && appData.jobs && appData.jobs.length) ? appData.jobs.map((task: any) => (
                    <Accordion key={task.id}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{task.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <ListItemText primary="Category" secondary={task.category} />
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <ListItemText primary="Youtube Url" secondary={task.youtubeUrl} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <ListItemText primary="Description" secondary={task.description} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Button onClick={() => { }}>Pick</Button>
                                    {userDetails.isAdmin && <Button onClick={() => handleOnDelete(task._id)}>Delete</Button>}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )) : <ListItem><Typography color="gray">No Jobs Now</Typography></ListItem>}
            </List>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Add New Task</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingTop: 10 }}>
                    <TextField
                        required
                        label="Category"
                        fullWidth
                        value={newTask.category}
                        onChange={(e) => setNewTask({ ...newTask, category: e.target.value })}
                    />
                    <TextField
                        required
                        label="Title"
                        fullWidth
                        value={newTask.title}
                        onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
                    />
                    <TextField
                        required
                        label="Description"
                        multiline
                        rows={4}
                        fullWidth
                        value={newTask.description}
                        onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
                    />
                    <TextField
                        required
                        label="Amount"
                        multiline
                        fullWidth
                        value={newTask.payment}
                        onChange={(e) => setNewTask({ ...newTask, payment: e.target.value })}
                    />
                    <TextField
                        required
                        label="Youtube Url"
                        multiline
                        fullWidth
                        value={newTask.youtubeUrl}
                        onChange={(e) => setNewTask({ ...newTask, youtubeUrl: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddTask} color="primary">
                        Add Task
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default FreelanceTaskScreen;
